import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueCompositionAPI from '@vue/composition-api';
import VueDaumPostcode from 'vue-daum-postcode';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/js/languages/ko';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import './index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faEdit,
  faTrash,
  faEye,
  faExclamationCircle,
  faBars,
  faCalendarDay,
  faTimesCircle,
  faCheck,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueMobileDetection from 'vue-mobile-detection';
import 'vee-validate';
import VueFroala from 'vue-froala-wysiwyg';

library.add(
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faEdit,
  faTrash,
  faEye,
  faExclamationCircle,
  faBars,
  faInstagram,
  faYoutube,
  faCalendarDay,
  faTimesCircle,
  faCheck,
  faArrowUp
);

Vue.config.productionTip = false;
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(VueCompositionAPI);
Vue.use(VueDaumPostcode);
Vue.use(VueFroala);
Vue.use(FontAwesomeIcon);
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});
Vue.use(VueMobileDetection);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
