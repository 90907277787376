import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/projects/:category',
    name: 'Project',
    component: () => import('../views/Project.vue'),
  },
  {
    path: '/process',
    name: 'Process',
    component: () => import('../views/Process.vue'),
  },
  {
    path: '/counseling',
    name: 'Counseling',
    component: () => import('../views/Counseling.vue'),
  },
  {
    path: '/v',
    name: 'CounselingList',
    component: () => import('../views/CounselingList.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogin) {
        return next();
      } else {
        return next({ name: 'Login' });
      }
    },
  },
  {
    path: '/v/:token',
    name: 'CounselingDetail',
    component: () => import('../views/CounselingDetail.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogin) {
        return next();
      } else {
        return next({ name: 'Login' });
      }
    },
  },
  {
    path: '/license',
    name: 'License',
    component: () => import('../views/License.vue'),
  },
  {
    path: '/press',
    name: 'Press',
    component: () => import('../views/Press.vue'),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogin) {
        return next();
      } else {
        return next({ name: 'Login' });
      }
    },
  },
  {
    path: '/edit/:category/:projectCategory?/:title',
    name: 'Edit',
    component: () => import('../views/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogin) {
        return next();
      } else {
        return next({ name: 'Login' });
      }
    },
  },
  {
    path: '/detail/:category/:projectCategory?/:title',
    name: 'Detail',
    component: () => import('../views/Detail.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogin) {
        return next({ name: 'Admin' });
      } else {
        return next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
