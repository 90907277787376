import { getCurrentInstance } from '@vue/composition-api';

export function useRouter() {
  const vm = getCurrentInstance();

  if (!vm) {
    throw new Error('컴포넌트 인스턴스 내에서 호출되지 않음');
  }

  const router = vm.proxy.$router;

  return router;
}
